.dropdown-menu {
	padding: 10px 0;
	transition: all .3s ease-in-out;
	border-radius: 0;
	margin: 0;
	@include media-breakpoint-down(md) {
		max-height: 0;
		display: block;
		position: relative;
		background-color: transparent;
		border: none;
		box-shadow: none;
		width: 100%;
		float: none;
		overflow: hidden;
		padding: 0 0 0 20px;
	}
}

.open > .dropdown-menu {
	max-height: 999px;
}
.mc4wp-form{
	.mc4wp-response {
		@extend .alert;
		&.mc4wp-error{
			@extend .alert-danger;
		}
		&.mc4wp-success {
			@extend .alert-success;
		}
	}
}
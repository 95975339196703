// WordPress Generated Classes
// http://codex.wordpress.org/CSS#WordPress_Generated_Classes

// Media alignment
.alignnone {
  margin-left: 0;
  margin-right: 0;
  max-width: 100%;
  height: auto;
}

.aligncenter {
  display: block;
  margin: ($spacer / 2) auto;
  height: auto;
}

.alignleft,
.alignright {
  margin-bottom: ($spacer / 2);
  height: auto;
}

@include media-breakpoint-up(sm) {
  // Only float if not on an extra small device
  .alignleft {
    float: left;
    margin-right: ($spacer / 2);
  }
  .alignright {
    float: right;
    margin-left: ($spacer / 2);
  }
}

// Captions
.wp-image{
  display: inline-block;
}
.wp-caption, .wp-image {
  @extend .figure-img;
  @extend .img-fluid;
  background: #fff;
  border-radius: $border-radius;
  overflow: hidden;
  position: relative;
  &:before{
    @extend .fa;
    @extend .fa-search-plus;
    position: absolute;
    width: 100%;
    color: rgba(255,255,255,0.8);
    font-size: 2rem;
    text-align: center;
    top: 50%;
    transform: translateY(-50%);
    zoom: 1;
    z-index: 999;
    transition: all .3s ease-in-out;
    opacity: 0;
  }
  &:hover{
    &:before{
      cursor: pointer;
      font-size: 3rem;
      opacity: 1;
    }
  }
}

.wp-caption-text {
  @extend .figure-caption;
  padding: 8px;
  line-height: 1;
}

// Text meant only for screen readers
.screen-reader-text {
  @extend .sr-only;
  @extend .sr-only-focusable;
}

// Images
img[class*="wp-image"] {
  @include transition(opacity .3s ease-in-out);
  opacity: 1;
  &:hover {
    cursor: pointer;
    opacity: .9;
  }
}
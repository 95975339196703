body > footer {
	background-color: $body-color;
	color: #fff;
	position: relative;
	.top {
		padding-top: 1.5rem;
		padding-bottom: 1.5rem;
		@include media-breakpoint-up(md) {
			padding-top: 3.5rem;
			padding-bottom: 1.5rem;
		}
		@include media-breakpoint-up(lg) {
			padding-top: 5.3rem;
			padding-bottom: 2.2rem;
		}
	}

	.logo {
		background: transparent url('../images/logo-icon.svg') no-repeat;
		height: 50px;
		width: 50px;
		background-position: center top;
		position: absolute;
		top: -25px;
		background-size: contain;
		left: 50%;
		margin-left: -25px;
		@include media-breakpoint-up(md) {
			height: 70px;
			width: 70px;
			top: -35px;
			margin-left: -35px;
		}
	}
	h3 {
		font-family: $headings-font-family;
		color: $blue-color;
		text-transform: uppercase;
		margin: 0;
		font-size: 2.2rem;
	}
	ul, .list-inline {
		margin-bottom: 0;
	}
	.widget {
		margin-bottom: 1rem;
	}
	.bottom {
		background-color: #000;
		color: #929090;
		line-height: 1.3;
		font-size: 0.75rem;
		padding: .5rem 0;
		font-size: 0.75rem;
		.poweredby {
			a {
				color: #929090;
			}
		}
	}

	.mc4wp-form {
		input.form-control {
			border-color: $blue-color;
		}
		label {
			color: #fff;
		}
		.btn {
			background: transparent;
			color: $blue-color;
			border: none;
			padding-right: 0;
		}
	}

	.fa {
		&.fa-linkedin-square,
		&.fa-instagram,
		&.fa-twitter-square,
		&.fa-facebook-square,
		&.fa-youtube-square {
			font-size: 1.875rem;
			color: #fff;
		}
		&.fa-linkedin-square:hover {
			color: #0077b5;
		}
		&.fa-instagram:hover {
			color: #f77737;
		}
		&.fa-twitter-square:hover {
			color: #1da1f2;
		}
		&.fa-facebook-square:hover {
			color: #3b5998;
		}
		&.fa-youtube-square:hover {
			color: #ff0000;
		}
	}

	.contact {
		margin-top: 1rem;
	}

	.footer-nav {
		display: inline-block;
		> li {
			display: inline-block;
			@include media-breakpoint-up(md) {
				margin-left: 1rem;
				&:before {
					@include fa-icon();
					content: $fa-var-circle;
					display: inline-block;
					font-size: .3rem;
					line-height: 1;
					position: relative;
					top: -0.25rem;
					margin-right: 1rem;
				}
			}
		}
	}

	.legal.table {
		margin: 1rem 0 0;
	}
	.table ul.list-table > li {
		vertical-align: middle;

	}
	.table ul.list-table > li.logo-icon {
		width: 50px;
		display: none;
		@include media-breakpoint-up(sm) {
			display: table-cell;
		}
		img {
			width: 35px;
			height: auto;
		}
	}
}
.navbar-brand {
	background: url('../images/logo.svg') no-repeat;
	width: 182px;
	height: 40px;
	overflow: hidden;
	text-indent: -999px;
	background-size: cover;
	line-height: 0;
	padding: 0;
	@include media-breakpoint-up(lg) {
		width: 200px;
		height: 44px;
	}
	@include media-breakpoint-up(xl) {
		width: 300px;
		height: 66px; 
	}
}
.btn {
	@include transition(all .3s ease-in-out);
	border-width: 1px;
}

.btn-transparent {
	@include button-variant($btn-transparent-color, $btn-transparent-bg, $btn-transparent-border);
	&:hover, &:focus, &:active {
		background-color: rgba(255, 255, 255, .2);
	}
}

.btn-white {
	@include button-variant($btn-white-color, $btn-white-bg, $btn-white-border);
	&:hover, &:focus, &:active {
		background-color: rgba(255, 255, 255, .2);
	}
}

.btn-lg {
	font-family: $font-family-semibold;
}

.btn-fa {
	background: transparent;
	border: none;
	text-indent: -999px;
	overflow: hidden;
	background-size: contain;
	&:focus, &:active {
		outline: none;
	}
}
.wrap {
	position: relative;
	padding-bottom: 2.5rem;
	&:before {
		content: ' ';
		position: absolute;
		background: transparent url('../images/header_clipping_large.svg') no-repeat;
		background-position: center top;
		top: 190px;
		left: 0;
		right: 0;
		bottom: 0;

		@media screen and (min-width: 375px) and (orientation: portrait) {
			top: 170px;
		}
		@media screen and (min-width: 375px) and (orientation: landscape) {
			top: 170px;
		}

		@media screen and (min-width: 400px) and (orientation: portrait) {
			top: 105px;
		}

		@media screen and (min-width: 425px) and (orientation: portrait) {
			top: 110px;
		}
		@media screen and (min-width: 425px) and (orientation: landscape) {
			top: 100px;
		}

		@include media-breakpoint-up(md) {
			top: 115px;
		}
		@include media-breakpoint-up(lg) {
			top: 150px;
			background-size: 102%;
		}
	}
	.container {
		position: relative;
		z-index: 10;
	}
}

body {
	position: relative;
	font-smooth: always;
	-webkit-font-smoothing: antialiased;
	text-rendering: optimizeLegibility;
	background-color: transparent;
	//&:before {
	//	content: ' ';
	//	position: absolute;
	//	background: #fff url('../images/header.jpg') no-repeat;
	//	width: 100%;
	//	background-position: center top;
	//	background-size: cover;
	//	height: 800px;
	//}
	&.page-template-default {
		h2, .h2 {
			padding-top: 1.2rem;
		}
	}
	&.post,
	&.page:not(.home),
	&.single,
	&.error404,
	&.search-no-results {
		.main {
			> h3 {
				margin-top: 2.5rem;
			}
		}
		.wrap > .content {
			background: #fff;
			border-radius: 5px 5px 0 0;
			padding-bottom: 3rem;
		}
	}
	&.single-producten {
		article figure.featured {
			@include media-breakpoint-up(xl) {
				height: 300px;
				width: 300px;
				float: left;
				margin: 0 1rem 1rem 0;
			}
		}
		&.speedstrap-pallet-stretch-band{
			article figure.featured {
				display: none;
			}
		}
	}
}

article {
	figure.featured {
		background-size: contain;
		background-repeat: no-repeat;
		height: 150px;
		background-position: center;
		@include media-breakpoint-up(md) {
			height: 200px;
		}
		@include media-breakpoint-up(lg) {
			height: 250px;
		}
		@include media-breakpoint-up(xl) {
			height: 300px;
		}
	}
	&.post {
		header {
			margin-bottom: 30px;
			a {
				color: RGBA(61, 64, 69, 0.5);
			}
			.meta {
				background: $breadcrumb-bg;
				color: RGBA(61, 64, 69, .5);
				margin-top: 30px;
				font-size: .9rem;
				padding: 0 1rem;
				border-radius: 5px;
				@include media-breakpoint-up(md) {
					font-size: 1rem;
				}

				.col {
					margin: 8px 0;
				}
			}
		}
	}
}

.wrap .container {
	background: transparent;
	padding-top: 15px;
	@include media-breakpoint-up(md) {
		background: transparent;
		padding-top: 0;
	}
}
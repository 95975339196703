// https://css-tricks.com/ie-10-specific-styles/
html[data-useragent*='Windows'],
html[data-useragent*='MSIE'] {
    .flex-grow {
        flex: none;
        -ms-flex-positive: 1;
        display: block;
    }

    .container,
    .row{
        width: 100%;
    }
    .form-group{
        width: auto;
    }

    body {
        letter-spacing: normal;
        &.home {
            section {
                // Featured
                &.featured {
                    .col-3 {
                        > div {
                            @include media-breakpoint-up(lg) {
                                min-width: 250px;
                            }
                        }
                    }
                }
            }
        }
    }
}
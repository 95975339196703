@font-face {
	font-family: 'DosisRegular';
	src: url('../fonts/dosis-regular-webfont.woff2') format('woff2'), url('../fonts/dosis-regular-webfont.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'DosisBold';
	src: url('../fonts/dosis-bold-webfont.woff2') format('woff2'), url('../fonts/dosis-bold-webfont.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'TekoMedium';
	src: url('../fonts/teko-medium-webfont.woff2') format('woff2'), url('../fonts/teko-medium-webfont.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'DosisSemiBold';
	src: url('../fonts/dosis-semibold-webfont.woff2') format('woff2'), url('../fonts/dosis-semibold-webfont.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}
body {
	&.modal-open {
		.wrap,
		.navbar-default,
		.slider,
		footer {
			@include filter(grayscale(100%));
		}
	}
}

.modal {
	&.bootbox-img {
		.modal-body {
			padding: 0;
		}
		.modal-content {
			overflow: hidden;
		}
		img {
			width: 100%;
		}
		.modal-dialog {
			position: absolute;
			top: 50%;
			transform: translate(-50%, -100%);
			margin: 0;
			width: 100%;
			left: 50%;
			@include media-breakpoint-up(md) {
				width: 80%;
			}
			@include media-breakpoint-up(lg) {
				max-width: 800px;
			}
		}
		&.in {
			.modal-dialog {
				transform: translate(-50%, -50%);
			}
		}
	}
	iframe{
		min-height: 200px;
		@include media-breakpoint-up(sm) {
			min-height: 220px;
		}
		@include media-breakpoint-up(md) {
			min-height: 500px;
		}
	}
}

// Scale up the modal
@include media-breakpoint-up(sm) {
	// Automatically set modal's width for larger viewports
	.modal-dialog {
		max-width: $modal-md;
		margin: $modal-dialog-sm-up-margin-y auto;
	}
}
@mixin flexbox {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    //display: -ms-flexbox;
    display: flex;
}

// Flexbox Wrap
//
// The 'flex-wrap' property controls whether the flex container is single-line
// or multi-line, and the direction of the cross-axis, which determines
// the direction new lines are stacked in.
//
// Values: nowrap | wrap | wrap-reverse
// Default: nowrap
//
// http://w3.org/tr/css3-flexbox/#flex-wrap-property

@mixin flex-wrap($value: nowrap) {
    // No Webkit Box fallback.
    -webkit-flex-wrap: $value;
    -moz-flex-wrap: $value;
    @if $value == nowrap {
        //-ms-flex-wrap: none;
    } @else {
        //-ms-flex-wrap: $value;
    }
    flex-wrap: $value;
}

@include media-breakpoint-up(sm){
    .row-flex, .row-flex > div[class*='col-'] {
        @include flexbox();
        flex:1 1 auto;
    }

    .row-flex-wrap {
        -webkit-flex-flow: row wrap;
        align-content: flex-start;
        flex:0;
    }

    .row-flex > div[class*='col-'], .container-flex > div[class*='col-'] {
        margin:-.2px; /* hack adjust for wrapping */
    }

    .container-flex > div[class*='col-'] div,.row-flex > div[class*='col-'] div {
        width:100%;
    }


    .flex-col {
        @include flexbox();
        flex: 1 100%;
        flex-flow: column nowrap;
    }

    .flex-grow {
        @include flexbox();
        -webkit-flex: 2;
        flex: 2;
    }
}
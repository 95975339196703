.navbar {
	margin-bottom: 0;
	padding: 0 1rem;
	.navbar-nav > li {
		@extend .nav-item;
		> a {
			@extend .nav-link;
		}
	}
	> .container {
		padding: 0;
		@include media-breakpoint-up(sm) {
			padding-left: 15px;
			padding-right: 15px;
		}
	}
}

.navbar-default {
	border: none;
	padding-top: 10px;
	padding-bottom: 10px;
	@include media-breakpoint-up(md) {
		padding-top: 15px;
		padding-bottom: 15px;
	}
	.nav > li {
		float: none;
		display: block;
		@include media-breakpoint-up(lg) {
			display: inline-block;
			padding: 15px 0;
		}
	}
	.nav > li > a {
		padding: 2px 0;
		margin: 0 15px;
		@include media-breakpoint-up(sm) {
			margin-left: 8px;
			margin-right: 8px;
		}
	}
	.navbar-header {
		@include media-breakpoint-up(sm) {
			text-align: center;
			display: block;
			float: none;
		}
		@include media-breakpoint-up(sm) {
			float: left;
		}
	}
	.navbar-nav {
		display: inline-block;
		//@include media-breakpoint-up(md) {
		//	margin-right: 15px;
		//}
		@include media-breakpoint-up(lg) {
			margin-right: 30px;
		}
		> li > a {
			position: relative;
		}
	}
	.navbar-nav > li > a,
	.navbar-text {
		color: #000;
		border-bottom: 2px solid transparent;
		transition: border-color .3s ease-in-out;
		font-family: $font-family-bold;
		@include media-breakpoint-up(lg) {
			color: #fff;
		}
	}
	.navbar-nav > .active > a,
	.navbar-nav > .active > a:focus,
	.navbar-nav > .active > a:hover,
	.navbar-nav > li > a:focus,
	.navbar-nav > li > a:hover {
		background-color: transparent;
		@include media-breakpoint-up(md) {
			border-bottom: 2px solid #fff;
		}
	}
	.navbar-toggleable {
		background: transparent;
		position: relative;

		@include media-breakpoint-down(md) {
			padding: .75rem .25rem;
			background: #fff;
			position: absolute;
			left: 0;
			top: 82px;
			right: 0;
			height: auto !important;
			width: 94%;
			margin: 0 auto;
			-webkit-box-shadow: 0 20px 34px 0 rgba(0, 0, 0, 0.1);
			box-shadow: 0 20px 34px 0 rgba(0, 0, 0, 0.1);
			-webkit-transform: translate3d(0, -125%, 0);
			transform: translate3d(0, -125%, 0);
			opacity: 0;
			border-radius: 5px;
			overflow: hidden;
			z-index: 100;
			-webkit-transition: opacity .3s, -webkit-transform .3s cubic-bezier(0.76, 1, 0.64, 0.87);
			transition: opacity .3s, -webkit-transform .3s cubic-bezier(0.76, 1, 0.64, 0.87);
			transition: transform .3s cubic-bezier(0.76, 1, 0.64, 0.87), opacity .3s;
			transition: transform .3s cubic-bezier(0.76, 1, 0.64, 0.87), opacity .3s, -webkit-transform .3s cubic-bezier(0.76, 1, 0.64, 0.87);

			.navbar-nav > li > a, .navbar-text {
				font-family: $font-family-base;
				font-size: 1.25rem;
			}
		}
		@include media-breakpoint-up(md) {
			max-width: 60%;
			padding: 3rem;
		}
		@include media-breakpoint-up(lg) {
			padding: 0;
			max-width: none;
		}
	}
	.collapse {
		@include media-breakpoint-up(md) {
			float: right;
		}
	}
}

.is-expanded .navbar-toggleable {
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
	opacity: 1;
}

.navbar-brand {
	float: left;
	padding-top: 10px;
	img {
		width: 160px;
		@include media-breakpoint-up(lg) {
			width: 250px;
		}
		@include media-breakpoint-up(xl) {
			width: 300px;
		}
	}
	@include media-breakpoint-up(xl) {
		padding-top: 5px;
	}
}

.navbar-toggler {
	float: right;
	&:focus {
		outline: none;
		background-color: transparent;
	}
}

.nav-hamburger {
	position: relative;
	z-index: 4;
	border: 0;
	//display: none;
	outline: 0;
	padding: 12px 8px 10px 8px;
	line-height: 1;
	background: transparent;
	cursor: pointer;
	margin: 0 0 0 auto;
	-webkit-transform: scale(0.8);
	-ms-transform: scale(0.8);
	transform: scale(0.8);
	.box {
		width: 30px;
		height: 21px;
		display: inline-block;
		position: relative;
		-webkit-transition: -webkit-transform .3s;
		transition: -webkit-transform .3s;
		transition: transform .3s;
		transition: transform .3s, -webkit-transform .3s;
		padding-top: 9px;
	}
	.inner, .inner::before, .inner::after {
		width: 30px;
		height: 3px;
		border-radius: 2px;
		position: absolute;
		-webkit-transition: -webkit-transform 0.15s ease;
		transition: -webkit-transform 0.15s ease;
		transition: transform 0.15s ease;
		transition: transform 0.15s ease, -webkit-transform 0.15s ease;
		background-color: #fff;
		left: 0;
	}
	.inner::before, .inner::after {
		content: "";
		display: block;
	}
	.inner::before {
		top: -9px;
		-webkit-transition: top 0.1s 0.34s ease-in, opacity 0.1s ease-in;
		transition: top 0.1s 0.34s ease-in, opacity 0.1s ease-in;
	}
	.inner::after {
		bottom: -9px;
		-webkit-transition: bottom 0.1s 0.34s ease-in, -webkit-transform 0.3s cubic-bezier(0.55, 0.055, 0.675, 0.19);
		transition: bottom 0.1s 0.34s ease-in, -webkit-transform 0.3s cubic-bezier(0.55, 0.055, 0.675, 0.19);
		transition: bottom 0.1s 0.34s ease-in, transform 0.3s cubic-bezier(0.55, 0.055, 0.675, 0.19);
		transition: bottom 0.1s 0.34s ease-in, transform 0.3s cubic-bezier(0.55, 0.055, 0.675, 0.19), -webkit-transform 0.3s cubic-bezier(0.55, 0.055, 0.675, 0.19);
	}
}

.is-expanded {
	.box {
		-webkit-transform: rotate(-45deg);
		-ms-transform: rotate(-45deg);
		transform: rotate(-45deg);
	}
	.inner {
		-webkit-transition-delay: 0.14s;
		transition-delay: 0.14s;
		-webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
		transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
	}
	.inner::before {
		top: 0;
		opacity: 0;
		-webkit-transition: top 0.1s ease-out, opacity 0.1s 0.14s ease-out;
		transition: top 0.1s ease-out, opacity 0.1s 0.14s ease-out;
	}
	.inner::after {
		bottom: 0;
		-webkit-transform: rotate(-90deg);
		-ms-transform: rotate(-90deg);
		transform: rotate(-90deg);
		-webkit-transition: bottom 0.1s ease-out, -webkit-transform 0.3s 0.14s cubic-bezier(0.215, 0.61, 0.355, 1);
		transition: bottom 0.1s ease-out, -webkit-transform 0.3s 0.14s cubic-bezier(0.215, 0.61, 0.355, 1);
		transition: bottom 0.1s ease-out, transform 0.3s 0.14s cubic-bezier(0.215, 0.61, 0.355, 1);
		transition: bottom 0.1s ease-out, transform 0.3s 0.14s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.3s 0.14s cubic-bezier(0.215, 0.61, 0.355, 1);
	}
}

.container-fluid > .navbar-collapse, .container-fluid > .navbar-header, .container > .navbar-collapse, .container > .navbar-header {
	margin-left: -15px;
	margin-right: -15px;
	@include media-breakpoint-up(sm) {
		margin-left: 0;
		margin-right: 0;
	}
}

.brand-container {
	@include media-breakpoint-down(sm) {
		&:after {
			content: "";
			display: table;
			clear: both;
		}
	}
}

.navbar-default {
	.dropdown li a {
		@extend .dropdown-item;
	}
	.dropdown-menu {
		&.depth_0 li {
			&:hover {
				.sub-menu {
					display: block;
				}
			}
		}
		&.depth_1 {
			left: 100%;
			top: 0;
		}
	}
}

.dropdown-toggle::after {
	@include media-breakpoint-up(md) {
		@include fa-icon();
		content: $fa-var-angle-down;
		border: none;
		display: inline-block;
		width: auto;
		height: auto;
		margin-left: 8px;
	}
}
html {
	background-color: #fff;
}

h1, .h1 {
	font-size: $font-size-h1;
	color: $font-color-h1;
	@include media-breakpoint-down(sm) {
		font-size: $font-size-h1 - .5;
	}
}

h2, .h2 {
	font-size: $font-size-h2;
	color: $font-color-h2;
	@include media-breakpoint-down(sm) {
		font-size: $font-size-h2 - .5;
	}
}

h3, .h3 {
	font-size: $font-size-h3;
	font-family: $font-family-h3;
}

h4, .h4 {
	font-size: $font-size-h4;
	font-family: $font-family-h4;
}

h5, .h5 {
	font-size: $font-size-h5;
	font-family: $font-family-h5;
}

h6, .h6 {
	font-size: $font-size-h6;
	font-family: $font-family-h6;
}

img {
	max-width: 100%;
	height: auto;
}

.list-fa li:before {
	@include fa-icon();
	display: inline-block;
	color: #007de1;
	margin-right: 5px;
}

.list-fa-check li:before {
	content: $fa-var-check;
}

.text-center {
	text-align: center;
}

.text-left {
	text-align: left;
}

.text-right {
	text-align: right;
}

.table {
	display: table;
	width: 100%;
	@each $breakpoint, $container-max-width in $container-max-widths {
		@include media-breakpoint-up($breakpoint, $grid-breakpoints) {
			max-width: $container-max-width;
		}
	}
	.row {
		display: table-row;
	}
	@each $breakpoint in map-keys($grid-breakpoints) {
		@for $i from 1 through $grid-columns {
			.col-#{$breakpoint}-#{$i} {
				display: table-cell;
				vertical-align: middle;
				width: percentage($i / $grid-columns);
			}
		}
	}

	ul.list-table {
		display: table-row;
		> li {
			display: table-cell;
		}
	}
}

.primary-border-top {
	position: relative;
	padding-top: 30px;
	display: inline-block;
	line-height: 1;
	&:before {
		content: ' ';
		width: 70px;
		height: 8px;
		background-color: $blue-color;
		position: absolute;
		top: 0;

	}
	&.border-right:before {
		right: 0;
	}
	&.border-left:before {
		left: 0;
	}
	&.border-center:before{
		left: 50%;
		margin-left: -35px;
	}
}

body{
	overflow-x: hidden;
}

.youtube-169{
	position: relative;
	padding-top: 56.25%;
	iframe{
		width: 100%;
		height: 100%;
		display: block;
		position: absolute;
		margin: 0;
		top: 0;
		left: 0;
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		box-sizing: border-box;
	}
}
// Grid system
.main {
    @include make-col-ready();
    @include media-breakpoint-up(sm) {
        @include make-col($main-sm-columns);
        .sidebar-primary & {
            @include make-col($main-sm-columns - $sidebar-sm-columns);
        }
    }
}

.sidebar {
    @include make-col-ready();
    @include media-breakpoint-up(sm) {
        @include make-col($sidebar-sm-columns);
    }
    h3 {
        display: block;
        width: 100%;
    }
}

.col-sm-offset-3 {
    @extend .push-sm-3;
}

.wrap {
    @include media-breakpoint-down(sm){
        //padding-top: 1rem;
    }
    .content {
        padding: 15px 0;
    }
}
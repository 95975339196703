.g-recaptcha {
	margin-bottom: 15px;
	text-align: right;
	> div {
		display: inline-block;
	}
}

.wpcf7-form {
	.g-recaptcha > div {
		border: 1px solid transparent;
	}
	&.invalid {
		.form-group {
			@include form-control-validation($brand-success);
			.form-control {
				background-image: $form-icon-success;
			}
			&.has-error {
				@include form-control-validation($brand-danger);
				.form-control {
					background-image: $form-icon-danger;
				}
			}
		}
		.g-recaptcha {
			> div {
				border-color: $brand-danger;
			}
		}
	}

	.form-group {
		.col-sm-9 {
			text-align: right;
			.btn-primary {
				@extend .btn-lg;
				@extend .text-uppercase;
				font-size: .88rem;
			}
		}
	}

	.ajax-loader {
		width: 0;
		overflow: hidden;
		display: inline-block;
		@include transition(width .3s ease-in-out);
		&:after {
			@include fa-icon();
			@extend .fa-spin;
			content: $fa-var-circle-o-notch;
		}
		&.is-active {
			width: 20px;
		}
	}

	&.invalid,
	&.valid {
		.wpcf7-form-control[aria-invalid="true"] {
			@extend .invalid;
		}

		.wpcf7-form-control[aria-invalid="false"] {
			@extend .valid;
		}
	}

	.wpcf7-response-output {
		@extend .alert;
		margin-top: 1rem;
		&.wpcf7-validation-errors,
		&.wpcf7-spam-blocked{
			@extend .alert-danger;
		}
		&.wpcf7-mail-sent-ok {
			@extend .alert-success;
		}
		&.wpcf7-display-none {
			display: none;
		}
	}

	label {
		color: $body-color;
	}

	.btn-fa {
		background: transparent url('../images/fa-icons/arrow-right.svg') no-repeat;
		background-size: 16px;
		width: 16px;
		height: 16px;
	}
}

.screen-reader-response {
	display: none;
}

.wpcf7-not-valid-tip {
	display: none;
}

.wpcf7{
	.inv-recaptcha-holder{
		display: none;
	}
}
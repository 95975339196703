// Grid settings
$enable-flex: true;
$enable-shadows: true;
$main-sm-columns: 12;
$sidebar-sm-columns: 4;
$enable-flex: true;
$container-max-widths: (
		sm: 700px,
		md: 720px,
		lg: 940px,
		xl: 1200px
) !default;

// Reboot
$link-hover-decoration: none;

// Colors
$brand-primary: #333;
$navbar-default-bg: #f6f6f6;
$body-bg: #f6f6f6;
$body-color: #2c2c2c;

$font-color-h1: $body-color;
$font-color-h2: $body-color;
$font-color-h3: $body-color;
$sep-line-color: #d9dada;
$yellow-color: #fcef17;
$blue-color: #007de1;
$link-color: $blue-color;
$border-color: #d4d4d4;
$grey-light: #f8f8f8;

// Fonts
$headings-font-family: 'TekoMedium', -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
$font-family-base: 'DosisRegular', -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
$font-family-semibold: 'DosisSemiBold', -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
$font-family-bold: 'DosisBold', -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;

$line-height-base: 1.5 !default;
$line-height-small: 1.2 !default;

$font-family-h1: $headings-font-family;
$font-family-h2: $headings-font-family;
$font-family-h3: $font-family-base;
$font-family-h4: $font-family-base;
$font-family-h5: $font-family-base;
$font-family-h6: $font-family-base;

$font-size-h1: 2.187rem !default;
$font-size-h2: 2.187rem !default;
$font-size-h3: 1.3rem !default;
$font-size-h4: 1.2rem !default;
$font-size-h5: 1.1rem !default;
$font-size-h6: 1rem !default;

// Butons
$btn-box-shadow: none;

$btn-padding-y: 5px;
$btn-padding-x: 10px;
$btn-padding-x-lg:               1rem !default;
$btn-padding-y-lg:               .6rem !default;

$btn-primary-color: #2c2c2c;
$btn-primary-bg: $yellow-color;
$btn-primary-border: $yellow-color;

$btn-transparent-color: #fff;
$btn-transparent-bg: rgba(255,255,255,.1);
$btn-transparent-border: #fff;

$input-btn-border-width: 0px;

$btn-white-color: #adadad;
$btn-white-bg: #fff;
$btn-white-border: #fff;


// Modals
$modal-md: 800px;
$modal-backdrop-opacity: .8;

// flag-icon-css
$flag-icon-css-path: '../images/flags/';
$flag-icon-rect-path: '/4x3';

// Alerts
$alert-border-radius: 0;

// Breadcrumbs
$breadcrumb-bg: #F9F9F9;
$breadcrumb-color: darken($breadcrumb-bg, 50%);

// Materialize
$input-height: 2.6875rem;

// Readability fixes
$text-shadow: 2px 1px 6px rgba(0, 0, 0, 0.3);

// Home Page
$home-page-section-padding-y: 2rem;
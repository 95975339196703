body.search {
	&:not(.search-no-results) {
		.sidebar .widget {
			background: #fff;
			padding: 1rem;
			border-radius: 0.25rem;
			margin-bottom: 1rem;
		}
	}
	&.search-no-results {
		.page-header h1 {
			color: $body-color;
		}
	}
	&.search-results{
		.main article,
		.main{
			background: transparent;
			padding-top: 0;
		}
	}
}
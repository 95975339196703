body {
	.page-header h1 {
		text-shadow: $text-shadow;
	}
	&.blog,
	&.category,
	&.post-type-archive-producten,
	&.search {
		.page-header h1 {
			color: #fff;
		}
		article .inner {
			@include box-shadow(0px 0px 5px rgba(0, 0, 0, 0.15));
		}
		article.hentry {
			margin-bottom: 30px;
			.inner {
				background-color: #fff;
				height: 100%;
				border-radius: 5px;
				overflow: hidden;
			}
			figure {
				height: 250px;
				margin: 1rem;
				background-size: contain;
				background-repeat: no-repeat;
				@include media-breakpoint-up(sm) {
					height: 200px;
				}
				@include media-breakpoint-up(lg) {
					height: 250px;
				}
			}
			header,
			.entry-summary,
			footer {
				padding: 0 15px;
			}
			header {
				margin-bottom: 0;
				h2 {
					font-size: $font-size-h2 - .4;
					height: ($font-size-h2 - .4) * 2.2;
					@include line-clamp(2);
					a, a:hover, a:focus, a:active {
						color: #000;
					}
				}
			}
			.entry-summary {
				@extend .flex-grow;
			}
			p {
				margin: 0;
			}
			footer,
			.entry-summary {
				padding-bottom: 15px;
			}
		}
	}
	&.sidebar-primary .main article,
	&.page.sidebar-primary .main{
		background: #fff;
		padding: 1rem;
		margin-bottom: 1rem;
		@include media-breakpoint-up(sm) {
			border-radius: 0.25rem;
		}
		.page-header h1{
			text-shadow: none;
		}
	}
}

.nav-links {
	@extend .clearfix;
	margin: 30px 0;
	.nav-previous,
	.nav-next {
		@extend .btn;
		@extend .btn-primary;
		a, a:hover, a:active, a:focus {
			color: $body-color;
		}
	}
	.nav-previous {
		float: right;
	}
	.nav-next {
		float: left;
	}
}
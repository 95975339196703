// Search form
.search-form {
    @extend .form-inline;
}

.search-form label {
    font-weight: normal;
    @extend .form-group;
}

.search-form .search-field {
    @extend .form-control;
}

.search-form .search-submit {
    @extend .btn;
    @extend .btn-secondary;
}

// Bootstrap 4.x compatibility
.form-group {
    @extend .row;
}

.form-control {
    padding-right: ($input-padding-x * 1);
    background-repeat: no-repeat;
    background-position: center right ($input-height / 4);
    background-size: ($input-height / 2) ($input-height / 2);
}

// Form validation states
.has-success {
    @include form-control-validation($brand-success);

    .form-control-success {
        background-image: $form-icon-success;
    }
}

.has-warning {
    @include form-control-validation($brand-warning);

    .form-control-warning {
        background-image: $form-icon-warning;
    }
}

.has-error {
    @include form-control-validation($brand-danger);
    .form-control {
        background-image: $form-icon-danger;
    }
}

div[id*=g-recaptcha] {
    margin-bottom: 15px;
}

body {
	figure.body-bg {
		margin: 0;
		position: absolute;
		width: 100%;
		overflow: hidden;
		img {
			display: block;
			position: relative;
			width: auto;
			max-width: none;
			height: 400px;
			left: 50%;
			margin-left: -525px;
			@include media-breakpoint-up(md) {
				height: 500px;
				margin-left: -560px;
			}
			@include media-breakpoint-up(lg) {
				height: 400px;
			}
			@include media-breakpoint-up(xl) {
				height: 550px;
				margin-left: -1079px;
			}
		}
	}
}

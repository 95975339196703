body {
	&.error404 {
		.content .main .container > .row {
			margin-top: (4 * $font-size-base);
		}
		.page-header h1{
			text-shadow: none;
		}
		.wrap > .container {
			background: #fff;
			padding: 1rem;
			margin-bottom: 1rem;
			@include media-breakpoint-up(sm) {
				border-radius: 0.25rem;
			}
		}
	}
}
.sidebar {
	.mc4wp-form {
		.btn {
			color: $body-color;
		}
	}
}

body {
	&.sidebar-primary,
	&.search {
		&:not(.search-no-results) {
			.sidebar .widget {
				background: #fff;
				padding: 1rem;
				border-radius: 0.25rem;
				margin-bottom: 1rem;
			}
		}
	}
}
.map {
	width: 100%;
	margin-bottom: 15px;
	height: 200px;
	-webkit-filter: grayscale(1);
	-webkit-filter: grayscale(100%);
	filter: grayscale(100%);
	transition: all .3s ease-in-out;
	&:hover {
		-webkit-filter: grayscale(0);
		-webkit-filter: grayscale(0%);
		filter: grayscale(0%);
	}
	@include media-breakpoint-up(md){
		height: 300px;
	}
	@include media-breakpoint-up(lg){
		height: 350px;
	}
	@include media-breakpoint-up(xl){
		height: 400px;
	}
	.route-link {
		display: block !important;
		position: absolute;
		bottom: 15px;
		left: 15px;
		z-index: 999;
		.fa {
			margin-right: 5px;
		}
	}
}
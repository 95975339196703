.team {
    .member {
        margin-bottom: 30px;
    }
    header {
        h4 {
            color: #000;
            //height: $font-size-base;
            font-size: $font-size-base;
            //line-height: $font-size-base - .2;
            margin: 0;
            //@include line-clamp(1);
        }
        p {
            color: $breadcrumb-active-color;
            margin: 0;
            font-size: .9rem;
        }
    }
    figure {
        a {
            display: block;
        }
        img{
            border: 1px solid $sep-line-color;
            @include media-breakpoint-up(xl){
                border: none;
            }
        }
        figcaption{
            color: $breadcrumb-active-color;
        }
    }
    .img-overlay {
        @include media-breakpoint-up(xl) {
            -webkit-box-shadow: inset 0px 2px 3px RGBA(160, 160, 160, 1.00);
            -moz-box-shadow: inset 0px 2px 3px RGBA(160, 160, 160, 1.00);
            box-shadow: inset 0px 2px 3px RGBA(160, 160, 160, 1.00);
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
        }
        border-radius: 100%;
    }
    .img-circle {
        height: 125px;
        width: 125px;
        @include media-breakpoint-up(lg) {
            height: 150px;
            width: 150px;
        }
        @include media-breakpoint-up(xl) {
            height: 165px;
            width: 165px;
        }
    }
}

body.single-staff{
    article.staff{
        > header{
            margin-bottom: ($font-size-base * 2);
        }
    }
    .img-overlay{
        display: none;
    }
    figure{
        img.img-circle{
            height: auto;
            @include media-breakpoint-up(lg){
                width: 100%;
                border: 1px solid $sep-line-color;
            }
        }
    }
}
// Text Styles
em { font-style: italic; }
strong { font-weight: 500; }
small { font-size: 75%; }
.light { font-weight: 300; }
.thin { font-weight: 200; }

.flow-text{
  font-weight: 300;
  $i: 0;
  @while $i <= $intervals {
    @media only screen and (min-width : 360 + ($i * $interval-size)) {
      font-size: 1.2rem * (1 + (.02 * $i));
    }
    $i: $i + 1;
  }

  // Handle below 360px screen
  @media only screen and (max-width: 360px) {
    font-size: 1.2rem;
  }
}
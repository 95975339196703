header nav .langswitcher{
	display: none;
	visibility: hidden;
	// Todo: restore below if other blogs are ready to roll;)
	//.btn-languageswitch{
	//	border-radius: 0;
	//	color: #adadad;
	//}
	//.dropdown-menu{
	//	min-width: 0;
	//	padding: 0;
	//}
	//@include media-breakpoint-up(md){
	//	display: inline-block;
	//}
	//@include media-breakpoint-down(sm){
	//	position: absolute;
	//	top: 1rem;
	//	right: 1rem;
	//}
}

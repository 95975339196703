@mixin form-control-validation($color) {
    // Color the label and help text
    .form-control-feedback,
    .form-control-label,
    .form-check-label,
    .form-check-inline,
    .custom-control {
        color: $color;
    }
    // Set the border and box shadow on specific inputs to match
    .form-control {
        border-color: $color;
        @include box-shadow(inset 0 1px 1px rgba(0, 0, 0, .075)); // Redeclare so transitions work

        &:focus {
            border-color: darken($color, 10%);
            $shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 6px lighten($color, 20%);
            @include box-shadow($shadow);
        }
    }

    // Set validation states also for addons
    .input-group-addon {
        color: $color;
        border-color: $color;
        background-color: lighten($color, 40%);
    }
    // Optional feedback icon
    .form-control-feedback {
        color: $color;
    }
}

@mixin filter($filters) {
    -webkit-filter: $filters;
    -ms-filter: $filters;
}

@mixin transition($transition) {
    transition: $transition;
    -webkit-transition: $transition;
}

@mixin line-clamp($lines) {
    -webkit-line-clamp: $lines;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
}

@mixin background-opacity($color, $opacity: 1) {
    background: $color; /* The Fallback */
    background: rgba($color, $opacity);
}
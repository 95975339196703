.home {
	// General
	.wrap .content {
		padding: 0;
		.btn {
			line-height: 1;
			@include media-breakpoint-up(lg) {
				padding: $btn-padding-y-lg $btn-padding-x-lg;
				font-family: $font-family-semibold;
			}
		}
	}
	.main {
		padding-left: 0;
		padding-right: 0;
	}
	.no {
		color: $blue-color;
		font-family: $headings-font-family;
		border-bottom: 10px solid $blue-color;
		font-size: 70px;
		display: inline-block;
		line-height: .85;
		margin-bottom: 1rem;
		@include media-breakpoint-up(md) {
			font-size: 100px;
			margin-bottom: 1.5rem;
		}
	}
	.cta {
		padding-bottom: 4rem;
		color: #fff;
		@include media-breakpoint-up(md) {
			margin: 1rem 0 5rem;
			padding-bottom: 0;
		}
		@include media-breakpoint-up(lg) {
			margin: 1.5rem 0 6.5rem;
		}
		@include media-breakpoint-up(xl) {
			margin: 5.5rem 0 10.5rem;
		}
		h1, p {
			text-shadow: $text-shadow;
		}
		h1 {
			color: #fff;
		}
		.list-inline {
			text-align: center;
			@include media-breakpoint-up(sm) {
				text-align: left;
				margin-top: 30px;
			}
			@include media-breakpoint-up(xl) {
				margin-top: 50px;
			}
			.list-inline-item:first-child {
				@include media-breakpoint-down(sm) {
					margin-bottom: 15px;
				}
			}
		}
	}

	iframe, video {
		box-shadow: 1px 1px 15px rgba(0, 0, 0, .3);
	}

	.logo-above-line {
		margin-bottom: 22px;
	}

	.content-00,
	.content-01,
	.content-02,
	.content-03,
	.content-05 {
		padding-top: 2rem;
		padding-bottom: 2rem;
		@include media-breakpoint-up(md) {
			padding-top: 2.8rem;
			padding-bottom: 2.8rem;
		}
		@include media-breakpoint-up(lg) {
			padding-top: 4rem;
			padding-bottom: 4rem;
		}
		.container {
			padding-top: 0;
		}
	}

	.content-00 {
		padding-top: 0;
		margin-top: -1rem;
		@include media-breakpoint-up(md) {
			margin-top: -2rem;
		}
		@include media-breakpoint-up(lg) {
			margin-top: -3rem;
		}
		h2 {
			padding-bottom: 1.5rem;
			margin-bottom: 0;
			@include media-breakpoint-up(xl) {
				padding-bottom: 2rem;
			}
		}
		.products {
			margin-bottom: 2.5rem;
			margin-left: 0;
			margin-right: 0;
		}
		.product {
			margin: 0 auto;
			@include media-breakpoint-down(md) {
				width: 25% !important;
			}
			@include media-breakpoint-down(sm) {
				width: 33.333% !important;
			}
			@include media-breakpoint-down(xs) {
				width: 50% !important;
			}
		}
		.product-img {
			height: 150px;
			width: auto;
			background-size: contain;
			background-repeat: no-repeat;
			background-position: center;
		}
		.caption {
			> a, > a:hover, > a:active, > a:focus {
				color: $body-color;
				text-decoration: none;
			}
		}
	}

	.content-01 {
		background-color: $grey-light;
		@include media-breakpoint-down(xs) {
			text-align: center;
			.primary-border-top.border-left:before {
				left: 50%;
				margin-left: -35px;
			}
		}
		@include media-breakpoint-up(sm) {
			ul {
				margin-bottom: 0;
			}
		}
		.list-fa {
			@include media-breakpoint-up(md) {
				font-size: 1.5rem;
				margin-top: 2rem;
			}
		}
		.mejs-controls {
			@include media-breakpoint-up(lg) {
				display: none;
			}
		}
	}

	.content-02 {
		h2 {
			line-height: 1;
			margin-bottom: 1rem;
			@include media-breakpoint-up(lg) {
				margin-bottom: 2rem;
			}
		}
		ul.list-inline {
			margin: 1.5rem 0 0;
			@include media-breakpoint-up(md) {
				margin-top: 2.8rem;
			}
		}
		.list-inline-item:not(:last-child) {
			margin-right: 2rem;
			@include media-breakpoint-up(lg) {
				margin-right: 4rem;
			}
		}
	}

	.content-03 {
		background-color: $grey-light;
		h2 {
			@include media-breakpoint-up(lg) {
				margin-bottom: 1rem;
			}
		}
		.list-fa-check li:before {
			color: $blue-color;
			margin-right: 5px;
		}
		.content {
			.list-inline {
				@include media-breakpoint-up(xl) {
					margin: 2.1rem 0 0;
				}
			}
			.list-inline-item {
				display: block;
				font-size: 1.2rem;
				@include media-breakpoint-up(lg) {
					display: inline-block;
					font-size: 1.5rem;
				}
			}
			.list-inline-item:not(:last-child) {
				@include media-breakpoint-up(xl) {
					margin-right: 1rem;
				}
			}
		}
		.bottom {
			@include media-breakpoint-up(md) {
				margin-top: 2rem;
			}
			.list-inline {
				margin-bottom: 0;
			}
			.list-inline-item {
				width: 19%;
				margin: 0;
			}
		}
	}

	.content-05 {
		h3 {
			font-family: $headings-font-family;
			font-size: 1.5rem;
			@include media-breakpoint-up(md) {
				margin-bottom: 1rem;
			}
		}
		.col-sm-6:not(:last-child) {
			margin-bottom: 1rem;
			@include media-breakpoint-up(sm) {
				margin-bottom: 0;
				border-right: 1px solid $border-color;
				padding-right: 2rem;
			}
			@include media-breakpoint-up(lg) {
				padding-right: 3rem;
			}
			@include media-breakpoint-up(xl) {
				padding-right: 3.75rem;
			}
			.content {
				border-bottom: 1px solid $border-color;
				margin-bottom: 1rem;
				padding-bottom: 1rem;
				@include media-breakpoint-up(sm) {
					border-bottom: none;
					margin-bottom: 0;
					padding-bottom: 0;
				}
			}
		}
		.col-sm-6:last-child {
			@include media-breakpoint-up(sm) {
				padding-left: 2rem;
			}
			@include media-breakpoint-up(lg) {
				padding-left: 3rem;
			}
			@include media-breakpoint-up(xl) {
				padding-left: 3.75rem;
			}
		}

		a:after {
			@include fa-icon();
			content: $fa-var-arrow-right;
			margin-left: 10px;
		}

		figure {
			@include media-breakpoint-up(md) {
				margin-bottom: 1.5rem;
			}
		}
	}
	.content-06 {
		background-color: $blue-color;
		padding: 1rem 0;
		color: #fff;
		line-height: 1;
		@include media-breakpoint-up(md) {
			padding: 1.5rem 0;
		}
		.col-sm-6:not(:last-child) {
			margin-bottom: 1rem;
			@include media-breakpoint-up(sm) {
				margin-bottom: 0;
			}
		}
		h3 {
			font-family: $headings-font-family;
			margin: 0;
			line-height: 1;
			font-size: 2rem;
			@include media-breakpoint-up(md) {
				font-size: 2.5rem;
				height: 30px;
			}
			@include media-breakpoint-up(lg) {
				font-size: 3rem;
				height: 40px;
			}
			@include media-breakpoint-up(xl) {
				font-size: 3.25rem;
			}
		}
		.content {
			@include media-breakpoint-up(md) {
				font-size: 1.25rem;
				font-family: $font-family-semibold;
			}
			@include media-breakpoint-up(lg) {
				font-size: 1.5rem;
			}
		}
		.list-inline {
			margin: 0;
		}
		.list-inline-item:first-child {
			margin-bottom: .5rem;
			@include media-breakpoint-up(md) {
				margin-bottom: 0;
			}
			@include media-breakpoint-up(xl) {
				margin-right: 1.5rem;
			}
		}
		.table {
			margin-bottom: 0;
		}
		.table, .row, .col-sm-6 {
			@include media-breakpoint-down(xs) {
				display: block;
				width: auto;
			}
		}
		.col-sm-6:first-child {
			@include media-breakpoint-up(sm) {
				padding-left: 0;
			}
			@include media-breakpoint-up(xl) {
				padding-left: 5rem;
			}
		}
		.col-sm-6:last-child {
			@include media-breakpoint-up(sm) {
				padding-right: 0;
			}
			@include media-breakpoint-up(xl) {
				padding-left: 4rem;
			}
		}
	}

	.content-07 {
		padding: 2rem 0;
		@include media-breakpoint-up(md) {
			padding: 2.8rem 0;
		}
		@include media-breakpoint-up(lg) {
			padding: 3.75rem 0;
		}
		h3 {
			font-family: $headings-font-family;
			margin-top: 0;
			font-size: 2rem;
			@include media-breakpoint-up(md) {
				font-size: 2.2rem;
			}
			@include media-breakpoint-up(md) {
				font-size: 2.4rem;
			}
		}
		.input-field {
			&:last-child {
				text-align: right;
			}
		}
	}
}
.metaslider {
	.flex-direction-nav{
		.flex-next:after,
		.flex-prev:after {
			@include fa-icon();
			@include media-breakpoint-up(lg){
				line-height: 40px;
			}
		}
		.flex-next{
			text-align: right;
		}
		.flex-prev{
			text-align: left;
		}
		.flex-next:after{
			content: $fa-var-angle-right;
		}
		.flex-prev:after{
			content: $fa-var-angle-left;
		}
	}

	.flexslider .flex-direction-nav li a{
		text-indent: 0 !important;
		background: none !important;
		font-size: 1.875rem;
		@include media-breakpoint-up(lg){
			font-size: 3.75rem;
			height: 40px !important;
			width: 80px !important;
			line-height: 40px;
		}
	}
}
.sidebar {
    margin-bottom: 30px;
    section {
        margin: 0 0 30px;
        &.widget_search{
            form{
                width: 100%;
            }
            .form-control{
                margin: 0;
            }
        }
        &.widget_nav_menu {
            ul.menu {
                margin: 0;
                padding: 0;
                > li {
                    list-style: none;
                    &:before {
                        @include fa-icon();
                        content: "\f00c";
                        color: $brand-primary;
                        margin-right: 5px;
                    }
                }
            }
        }
        // News widget
        &.widget_cp_newswidget {
            article {
                margin-bottom: 20px;
                display: block;
                width: 100%;
            }
            .row {
                margin-left: -5px;
                margin-right: -5px;
            }
            .col {
                padding: 0 5px;
            }
            figure {
                margin: 0;
            }
            header {
                h3 {
                    @include media-breakpoint-up(sm) {
                        margin: 8px 0 0;
                    }
                    @include media-breakpoint-up(lg) {
                        margin-top: 0;
                        margin-bottom: .5rem;
                    }
                }
            }
            .excerpt {
                @include line-clamp(2);
                @include media-breakpoint-up(md) {
                    @include line-clamp(3);
                }
                @include media-breakpoint-up(lg) {
                    @include line-clamp(2);
                }
                @include media-breakpoint-up(xl) {
                    @include line-clamp(3);
                }
                line-height: $line-height-small;
                max-height: ($font-size-base * 3) * $line-height-small;
            }
        }

        // Search
        &.widget_search {
            .input-group {
                @include media-breakpoint-up(lg) {
                    list-style: none;
                }
            }
        }

        &:first-child {
            @include media-breakpoint-up(md) {
                margin-bottom: ($font-size-base * 5);
            }
            @include media-breakpoint-up(lg) {
                margin-bottom: ($font-size-base * 3);
            }
        }
    }
}